"use client";
import PropTypes from "prop-types";
import Image from "next/image";
import parser from "html-react-parser";
import LineCard from "@/components/common/line-card";
import sendInternalPostRequest from "@/services/client/requests/sendInternalPostRequest";
import {
  useSelectCsrfToken,
  useDispatchShowLoader,
  useSelectActiveLineCards,
  useDispatchSetCart,
  useRedirect,
  useDispatchIncreaseActiveLineCards,
  useDispatchDecreaseActiveLineCards,
  useDispatchUnsetAllActiveLineCards,
  useSelectLocale,
  useDispatchShowToaster,
  useSelectGrandDrawDate,
} from "@/hooks";
import { checkNumberAndWrap } from "@/utils/functions.client";
import { cartAdd } from "@/services/client/endpoint";
import { DIGITS } from "@/utils/constants";
import { trackCartEvents } from "@/utils/gtm";
import { generateCartItem, gtmDateFormat } from "@/utils/functions.client";
import "@/app/[lang]/styles/style.css";
import "./index.css";
import { useSelector } from "react-redux";

export default function HomeLineCard({ playInfo = {}, currency = "" }) {
  const activeLineCards = useSelectActiveLineCards();
  const csrfToken = useSelectCsrfToken();
  const dispatchIncreaseCards = useDispatchIncreaseActiveLineCards();
  const dispatchDecreaseCards = useDispatchDecreaseActiveLineCards();
  const dispatchUnsetAllCards = useDispatchUnsetAllActiveLineCards();
  const dispatchShowLoader = useDispatchShowLoader();
  const dispatchShowToaster = useDispatchShowToaster();
  const dispatchSetCart = useDispatchSetCart();
  const redirect = useRedirect();
  const drawDate = useSelectGrandDrawDate();
  const gtmData = useSelector((state) => state?.authUser?.data);
  const totalCombination = playInfo?.totalCombination || DIGITS.FIVE;
  const lineCards = [];
  const locale = useSelectLocale();
  let fullCard = DIGITS.ZERO;

  for (let i = 0; i < (playInfo?.minTotalCards || DIGITS.TWO); i++) {
    if (activeLineCards[i]) {
      lineCards.push(
        <LineCard
          key={i}
          lineNumber={i + 1}
          selectedNumbers={activeLineCards[i]}
          isActive={true}
          disableLine={playInfo?.disableLine || ""}
          showFavouritesImage={playInfo?.showFavouritesImage || ""}
          disableShowFavourites={playInfo?.disableShowFavourites || false}
          addFavouritesImage={playInfo?.addFavouritesImage || ""}
          disableAddFavourites={playInfo?.disableAddFavourites || false}
          randomImage={playInfo?.randomImage || ""}
          disableRandomButton={playInfo?.disableRandomButton || false}
          clearImage={playInfo?.clearImage || ""}
          disableClearButton={playInfo?.disableClearButton || false}
          disableIncreaseButton={playInfo?.disableIncreaseButton || false}
          cardTitle={playInfo?.cardTitle || ""}
          totalCombination={totalCombination}
          totalLineDigits={playInfo?.totalLineDigits || 49}
        />
      );
      if (activeLineCards[i].length === totalCombination) fullCard++;
    } else {
      lineCards.push(
        <LineCard
          key={i}
          lineNumber={i + 1}
          selectedNumbers={[]}
          isActive={false}
          disableLine={playInfo?.disableLine || ""}
          showFavouritesImage={playInfo?.showFavouritesImage || ""}
          disableShowFavourites={playInfo?.disableShowFavourites || false}
          addFavouritesImage={playInfo?.addFavouritesImage || ""}
          disableAddFavourites={playInfo?.disableAddFavourites || false}
          randomImage={playInfo?.randomImage || ""}
          disableRandomButton={playInfo?.disableRandomButton || false}
          clearImage={playInfo?.clearImage || ""}
          disableClearButton={playInfo?.disableClearButton || false}
          disableIncreaseButton={playInfo?.disableIncreaseButton || false}
          cardTitle={playInfo?.cardTitle || ""}
          totalCombination={totalCombination}
          totalLineDigits={playInfo?.totalLineDigits || 49}
        />
      );
    }
  }

  return (
    <div className="col-lg-6">
      <div className="number-picker homepage">
        <div className="number-picker__top">
          <div className="number-picker__top--text d-flex justify-content-between">
            <div>
              <h5 className="number-picker__top--text_div  font-500">
                {parser(playInfo?.title ? playInfo.title.substring(0, 24) : "")}
              </h5>
              <h1
                className={`regular-bld  ${
                  locale === "en"
                    ? "number-picker__top--textFont45"
                    : "number-picker__top--textFont36"
                }`}
              >
                {parser(
                  checkNumberAndWrap(
                    playInfo?.ticketPrize,
                    "text",
                    "span",
                    "digits-iq text-price"
                  ) || ""
                )}
              </h1>
              {playInfo?.playDescription ? (
                <>
                  {parser(
                    checkNumberAndWrap(playInfo?.playDescription, "text") || ""
                  )}
                </>
              ) : null}
            </div>
            <div className="number-picker__top--img">
              <Image
                src={playInfo?.productImage || ""}
                alt="Water Bottle"
                style={{ width: "100%", height: "100%" }}
                width={65}
                height={245}
                loading="lazy"
              />
            </div>
            <div className="number-picker__bottle--value">
              <div
                className="value-button"
                id="decrease"
                onClick={() => {
                  if (!playInfo?.disableDecreaseButton) {
                    dispatchDecreaseCards();
                  }
                }}
              >
                <Image
                  src={playInfo?.minusImage || ""}
                  alt="Decrease"
                  style={{
                    width: "auto",
                    height: "auto",
                  }}
                  width={40}
                  height={40}
                  loading="lazy"
                />
              </div>
              <input
                type="number"
                aria-label="product_count"
                className="ffv digits-iq-bld !text-brown"
                data-max="2"
                id="product_cover_count"
                name="quantiny"
                value={activeLineCards?.length || DIGITS.ONE}
                onChange={(e) => {}}
                disabled
              />
              <div
                className="value-button"
                id="increase"
                onClick={() => {
                  if (!playInfo?.disableIncreaseButton) {
                    dispatchIncreaseCards();
                    if (parseInt(activeLineCards.length) >= DIGITS.TWO) {
                      redirect(playInfo?.playUrl || "/");
                    }
                  }
                }}
              >
                <Image
                  src={playInfo?.plusImage || ""}
                  alt="Increase"
                  style={{
                    width: "auto",
                    height: "auto",
                  }}
                  width={40}
                  height={40}
                  loading="lazy"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="number-picker__content">{lineCards}</div>

        <div className="number-picker--btn">
          <button
            className="btn btn--blue bold-iq"
            disabled={
              fullCard > 0 && !playInfo?.disableAddToCart ? false : true
            }
            id="atc-button"
            onClick={async (e) => {
              e.preventDefault();
              if (
                Array.isArray(activeLineCards) &&
                activeLineCards?.length > 0 &&
                !playInfo?.disableAddToCart
              ) {
                const items = [];
                const splitArraySize = DIGITS.DIGIT_50;
                const clonedCards = [...activeLineCards].filter(
                  (ar) => ar.length === totalCombination
                );
                for (let i = 0; i < clonedCards.length; i += splitArraySize) {
                  items.push(clonedCards.slice(i, i + splitArraySize));
                }
                if (items?.length > 0) {
                  try {
                    dispatchShowLoader(true);
                    const result = await sendInternalPostRequest({
                      endpoint: cartAdd,
                      data: { items },
                      csrfToken,
                    });

                    //for gtm
                    const itemVal = generateCartItem(
                      items,
                      gtmDateFormat(drawDate || ""),
                      gtmData?.gameID || ""
                    );

                    // Call trackCartEvents with the generated items
                    trackCartEvents({
                      event: "add_to_cart",
                      event_category: "ecommerce",
                      ecommerce: {
                        currency: "USD",
                        user_id: gtmData?.userid || "",
                        p_previouspagename: gtmData?.previousPage,
                        value: itemVal?.value * 3000,
                        items: itemVal?.item,
                      },
                    });
                    if (result?.success && Array.isArray(result?.cart)) {
                      redirect(playInfo?.cartUrl || "");
                      dispatchSetCart([...result.cart]);
                      dispatchUnsetAllCards();
                    }
                    dispatchShowLoader(false);
                  } catch (error) {
                    dispatchShowToaster(error.message);
                  } finally {
                    dispatchShowLoader(false);
                  }
                }
              }
            }}
          >
            {playInfo?.addToCart || ""}
          </button>
        </div>
      </div>
    </div>
  );
}

HomeLineCard.propTypes = {
  /**
   * Play info
   */
  playInfo: PropTypes.object.isRequired,
};

"use client";
import PropTypes from "prop-types";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/autoplay";
import "swiper/css/pagination";
import { Autoplay, Pagination } from "swiper/modules";
import "@/app/[lang]/styles/swiper.css";
import Image from "next/image";
import Link from "next/link";
import { useEffect } from "react";
import { useDispatchSetUseAvailableBalance } from "@/hooks";

export default function HomeSlider({
  bannerDesktopSlides = [],
  bannerMobileSlides = [],
}) {
  const dispatchSetCheckedAvailableBalance =
    useDispatchSetUseAvailableBalance();

  useEffect(() => {
    dispatchSetCheckedAvailableBalance(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="container p-0 p-md-auto">
      <div className="mt-md-3 mt-sm-0 d-block d-sm-none">
        <Swiper
          slidesPerView={1}
          spaceBetween={10}
          autoplay={true}
          pagination={true}
          modules={[Autoplay, Pagination]}
          className="custom_HomeSwiper"
        >
          {bannerMobileSlides?.map((item, index) => {
            return item?.isDisabled == false ? (
              <SwiperSlide
                key={`${item.id}-${index}`}
                className="homepage_swiperslider_div"
              >
                <Link href={item.link}>
                  <Image
                    loading="lazy"
                    src={item?.image}
                    alt={"Mobile Banner"}
                    width={100}
                    height={100}
                    className="homepage_image"
                  />
                </Link>
              </SwiperSlide>
            ) : null;
          })}
        </Swiper>
      </div>
      <div className="d-none d-sm-block mb-3">
        <Swiper
          slidesPerView={1}
          spaceBetween={10}
          autoplay={true}
          pagination={true}
          modules={[Autoplay, Pagination]}
          className="custom_HomeSwiper"
        >
          {bannerDesktopSlides?.map((item, index) => {
            return item?.isDisabled == false ? (
              <SwiperSlide
                key={`${item.id}-${index}`}
                className="homepage_swiperslider_div"
              >
                <Link href={item.link}>
                  <Image
                    src={item?.image}
                    alt={"Desktop Banner"}
                    width={100}
                    height={100}
                    className="homepage_image"
                  />
                </Link>
              </SwiperSlide>
            ) : null;
          })}
        </Swiper>
      </div>
    </div>
  );
}

HomeSlider.propTypes = {
  /**
   * Desktop slide images
   */
  bannerDesktopSlides: PropTypes.array.isRequired,
  /**
   * Mobile slide images
   */
  bannerMobileSlides: PropTypes.array.isRequired,
};

"use client";
import PropTypes from "prop-types";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import HomeSlider from "./slider";
import HomeDownload from "./download";
import HomeLineCard from "./line-card";
import HomeCountdown from "./countdown";
import HomeStories from "./stories";
import HomeResults from "./results";
import HomeWinnersMobile from "./winners-mobile";
import { setShowFavouritesContent } from "@/redux/slices/showFavouritesModalSlice";
import { setAddFavouritesContent } from "@/redux/slices/addFavouritesModalSlice";

export default function Home({ data = null }) {
  const videoSection = data?.isStoriesVideoDisabled;
  const dispatch = useDispatch();

  useEffect(() => {
    if (data?.showFavourites?.id > 0) {
      dispatch(
        setShowFavouritesContent({
          title: data?.showFavourites?.title || "",
          emptyFavouritesRemarks:
            data?.showFavourites?.emptyFavouritesRemarks || "",
        })
      );
    }
  }, [data?.showFavourites, dispatch]);
  useEffect(() => {
    if (data?.addFavourites?.id > 0) {
      dispatch(
        setAddFavouritesContent({
          title: data?.addFavourites?.title || "",
          skipButton: data?.addFavourites?.skipButton || {},
          saveButton: data?.addFavourites?.saveButton || {},
          favNameNotThere: data?.addFavourites?.favNameNotThere || "",
          limitExceededMsg: data?.addFavourites?.limitExceededMsg || "",
          favNameAlreadyThere: data?.addFavourites?.favNameAlreadyThere || "",
          favNoNotThere: data?.addFavourites?.favNoNotThere || "",
          favNoThere: data?.addFavourites?.favNoThere || "",
          FavNoDuplicate: data?.addFavourites?.FavNoDuplicate || "",
          FavNamePlaceholder: data?.addFavourites?.FavNamePlaceholder || "",
        })
      );
    }
  }, [data?.addFavourites, dispatch]);
  return (
    <>
      {!!data?.showBannerSlides ? (
        <HomeSlider
          bannerDesktopSlides={data?.bannerDesktopSlides || []}
          bannerMobileSlides={data?.bannerMobileSlides || []}
        />
      ) : (
        ""
      )}
      <div className="remodal__wrapper"></div>
      <div className="remodal__wrapper"></div>
      <section className="hp-navigation">
        <div className="container">
          <div className="hp-navigation__inner">
            <div className="row">
              <HomeLineCard
                playInfo={data?.playInfo || {}}
                currency={data?.topPrize?.currency}
              />
              <HomeCountdown
                countDownTimer={data?.countDownTimer || {}}
                topPrize={data?.topPrize || {}}
                promoPrize={data?.promoPrize || {}}
                ctaPrizes={data?.ctaPrizes || {}}
                totalWinnersLabel={data?.totalWinnersLabel || ""}
              />

              {data?.showStorySection === true && videoSection === false ? (
                <HomeStories data={data?.stories || {}} />
              ) : (
                ""
              )}
              {data?.showStorySection === true ? (
                <HomeResults
                  currency={data?.topPrize?.currency}
                  data={data?.results || {}}
                  isFullWidth={videoSection === true ? true : false}
                  // isFullWidth={
                  //   data?.showStorySection === true |
                  //     ? false
                  //     : true
                  // }
                />
              ) : (
                ""
              )}

              <HomeWinnersMobile label={data?.totalWinnersLabel || ""} />
            </div>
          </div>
        </div>
      </section>
      {data?.showDownloadSection === true ? (
        <HomeDownload
          desktopImage={data?.downloadDesktopImage || ""}
          mobileImage={data?.downloadMobileImage || ""}
          appStoreUrl={data?.downloadAppStore || ""}
          googlePlayUrl={data?.downloadGooglePlay || ""}
        />
      ) : (
        ""
      )}
    </>
  );
}

Home.propTypes = {
  /**
   * Home data from Strapi
   */
  data: PropTypes.object.isRequired,
};
